define('webapp/components/widgets/widget-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function isEmptyField(val) {
    if (typeof val === 'string') {
      return val.length === 0;
    } else if (Array.isArray(val)) {
      return val.length === 0;
    } else {
      return !val;
    }
  }

  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    message: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    i18n: Ember.inject.service(),
    languageService: Ember.inject.service(),
    activeTab: 0,

    form: Ember.computed('data.connection.item', 'externalForm', function () {
      var externalForm = this.get('externalForm');
      var dataConnectionItem = this.get('data.connection.item');

      if (externalForm) {
        return externalForm;
      } else if (dataConnectionItem) {
        return dataConnectionItem;
      } else {
        throw new Error('no form found');
      }
    }),

    formfields: Ember.computed.alias('form'),

    pagedFields: Ember.computed('form.fields', function () {
      var fields = this.get('form.fields');
      var pageBuckets = {};

      if (!fields) {
        return [];
      }

      fields.forEach(function (field) {
        var page = field.options && field.options.page || '1';

        if (!pageBuckets[page]) {
          pageBuckets[page] = [];
        }

        pageBuckets[page].push(field);
      });

      var pages = Object.entries(pageBuckets).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            page = _ref2[0],
            fields = _ref2[1];

        return { page: page, fields: fields };
      });
      return pages.sort(function (a, b) {
        return Number(a.page) - Number(b.page);
      });
    }),

    _getSuccessMessage: function _getSuccessMessage() {
      var i18n = this.get('i18n');
      var selectedLanguage = this.get('languageService.selectedLanguage');

      var message = i18n.t('forms.ui.formSent').toString();
      var notification = this.get('form.response.translations.' + selectedLanguage + '.notification') || this.get('form.response.notification');

      if (typeof notification === 'string' && notification.trim()) {
        message = notification.trim();
      }

      return message;
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var fields = this.get('form.fields');
      var result = {};
      if (fields) {
        fields.forEach(function (field) {
          if ((field.kind === 'radio' || field.kind === 'select') && field.options) {
            if (field.options.multi) {
              result[field.key] = [];
            }
          }
          if (field.kind === 'address') {
            result[field.key] = {};
          }
          if (field.kind === 'person') {
            result[field.key] = {};
          }
          // TODO JB: for table there should be something like this ?
          // if ((field.kind === 'table') && field.options) {
          //   result[field.key] = [];
          // }
        });
      }
      this.set('result', result);

      var userProxy = this.get('userProxy');
      if (userProxy.get('isLoggedIn')) {
        Ember.RSVP.all([userProxy.fetchOwnEntries(), this.get('cachedData').getFlatCategories()]).then(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
              entries = _ref4[0],
              categories = _ref4[1];

          var filterCat = categories.find(function (cat) {
            return cat.internalName === 'koeppl-importeur';
          });

          var ownEntry = entries[0];

          if (entries.length > 1 && filterCat) {
            var specialEntry = entries.find(function (entry) {
              return entry.categories.some(function (x) {
                return x === filterCat._id;
              });
            });
            if (specialEntry) {
              ownEntry = specialEntry;
            }
          }

          _this.set('ownEntry', ownEntry);

          // prefill fields
          if (ownEntry) {
            if (fields.find(function (field) {
              return field.key === 'vendorCompany';
            })) {
              _this.set('result.vendorCompany', ownEntry.title);
            }
            if (ownEntry.contact) {
              if (fields.find(function (field) {
                return field.key === 'vendorEmail';
              })) {
                _this.set('result.vendorEmail', ownEntry.contact.email);
              }
              if (fields.find(function (field) {
                return field.key === 'vendorPhone';
              })) {
                _this.set('result.vendorPhone', ownEntry.contact.phone);
              }
            }
          }
        });
      }
    },
    afterSubmit: function afterSubmit() {
      var redirectPageInternalName = this.get('data.options.redirectPageInternalName');
      if (!redirectPageInternalName) {
        var options = this.get('options');
        if (options && options.redirectPageInternalName) {
          redirectPageInternalName = options.redirectPageInternalName;
        }
      }

      if (redirectPageInternalName) {
        var cmsService = this.get('cmsService');
        cmsService.redirectToPageByInternalName(redirectPageInternalName, this.get('overrideRedirectDetail'));
      } else {
        var onSuccess = this.get('onSuccess');
        if (onSuccess) {
          onSuccess();
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      var mpForm = this.element.querySelector('#multipage-form');
      if (mpForm) {
        mpForm.addEventListener('show', function (e) {
          _this2.set('activeTab', e.detail[0].index());
        });
      }
    },


    actions: {
      nextTab: function nextTab() {
        var currentTab = this.get('activeTab');
        currentTab = currentTab + 1;
        this.set('activeTab', currentTab);
        UIkit.tab(this.element.querySelector('.multipage-tab')).show(currentTab);
        UIkit.switcher(this.element.querySelector('.multipage-tab')).show(currentTab);
      },
      previousTab: function previousTab() {
        var currentTab = this.get('activeTab');
        currentTab = currentTab - 1;
        this.set('activeTab', currentTab);
        UIkit.tab(this.element.querySelector('.multipage-tab')).show(currentTab);
        UIkit.switcher(this.element.querySelector('.multipage-tab')).show(currentTab);
      },
      submit: function submit() {
        var _this3 = this;

        if (this.get('isSubmitting')) {
          return;
        }

        var message = this.get('message');
        var i18n = this.get('i18n');
        var result = this.get('result');
        var formId = this.get('form._id');
        var fields = this.get('form.fields');
        var myStorage = this.get('myStorage');

        //check mandatory
        var missingFields = [];
        fields.forEach(function (field) {
          if (field.mandatory && isEmptyField(result[field.key])) {
            Ember.set(field, 'isMissing', true);
            missingFields.push(field);
          } else {
            Ember.set(field, 'isMissing', false);
          }
        });

        //check valid email
        var validEmail = true;
        fields.forEach(function (field) {
          if (field.kind === 'email') {
            validEmail = result[field.key] && result[field.key].trim().match(/^\S+@\S+$/);
          }
        });

        if (missingFields.length) {
          message.error(i18n.t('forms.ui.missingFields').toString() + '\n' + missingFields.map(function (x) {
            return x.name;
          }).join(', '));
        } else {
          if (!validEmail) {
            var invalidEmailMessage = i18n.t('forms.ui.invalidMail').toString();
            message.error(invalidEmailMessage);
          } else {
            fields.forEach(function (field) {
              if (field.kind === 'table') {
                result[field.key] = field.options.table;
              }
            });

            var data = {};

            var additionalData = this.get('additionalData');
            if (additionalData) {
              Object.entries(additionalData).forEach(function (_ref5) {
                var _ref6 = _slicedToArray(_ref5, 2),
                    key = _ref6[0],
                    value = _ref6[1];

                if (value != null) {
                  var type = typeof value === 'number' ? 'numeric' : 'text';
                  data[key] = { type: type, value: value };
                }
              });
            }

            Object.keys(result).forEach(function (key) {
              fields.forEach(function (field) {
                if (field.key === key && result[key]) {
                  data[key] = { type: field.kind, value: result[key] };
                }
              });
            });

            var opts = {
              data: {
                form: formId,
                fields: data
              },
              query: { lang: this.get('languageService.selectedLanguage') }
            };

            var formInfo = this.get('formInfo');
            if (formInfo) {
              opts.data['itemType'] = formInfo.type;
              opts.data['item'] = formInfo.id;
            }

            // send submission
            this.set('isSubmitting', true);

            myStorage.submission.create(opts).then(function () {
              _this3.set('isSubmitting', false);
              var successMessage = _this3._getSuccessMessage();
              message.info(successMessage);
              _this3.set('isSubmitted', true);
              _this3.set('formModalShown', false);
              _this3.afterSubmit();
            }).catch(function (err) {
              _this3.set('isSubmitting', false);
              var onError = _this3.get('onError');
              if (onError) {
                onError(err);
              } else {
                var errorMessage = i18n.t('forms.ui.formErr').toString();
                message.error(errorMessage + '\n' + err.payload.message);
              }
            });
          }
        }
      },
      filesSent: function filesSent(field, files) {
        var key = field.key;
        var result = this.get('result');

        Ember.set(result, key, files);
      },
      removeFile: function removeFile(file, fieldKey) {
        var result = this.get('result');

        var filteredResult = result[fieldKey].filter(function (upload) {
          return upload !== file;
        });
        Ember.set(result, fieldKey, filteredResult);
      },
      showFormModal: function showFormModal() {
        this.set('formModalShown', true);
      },
      cancelFormModal: function cancelFormModal() {
        this.set('formModalShown', false);
      }
    }
  });
});